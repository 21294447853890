export default function Home() {
  return (
    <div className="home-container">
      <div className="script-container">

      <p>
        Nascida com a arte no coração, professora de Artes, conheceu um caminho traçado
        por ela mesma, passando de pinturas, mais fortes e extensas, a histórias coloridas e
        matizadas, como se existissem, mas não ao longo do tempo, imaginárias.
      </p>

      <p>
        Paisagens empíricas, infinitas, afetadas por traços de formas e cores indefinidas e
        reflexos, por vezes distraídos, por ilusões de ótica. Realçam as cores suaves, em
        contraste com tintas tão ardentes como o fogo, que pertencem a uma época em que
        nasceu um paradigma: "Só conseguimos atingir a plenitude através do equilíbrio".
      </p>

      <p>
        Depois, visões suaves quebradas em duas, quase impressas e flutuando em cores
        mais desbotadas e uniformes.
      </p>
  
      <p>
        Paisagens toscanas nascem das belezas que esta região italiana oferece. Os
        ciprestes inevitáveis.
      </p>
  
      <p>
        As miniaturas, expressas em lajes de terracota, que incluem danças de bailarinas
        indomáveis e flores em cores fortes e abstratas. Figuras de nus.
      </p>
  
      <p>
        A galeria oferece um amplo panorama das obras realizadas nos últimos vinte anos,
        onde é possível apreciar as diversas expressões pictóricas nascidas de seu próprio
        pensamento: sejam óleos, acrílicos e aguarelas, até as demais técnicas que fazem
        parte do repertório da artista.
      </p>
  
      <p>
        É como rolar a tela de um filme ou caminhar por uma estrada ou ao longo de um
        caminho na floresta. As obras expressam o que são e tudo está à vista.
        Muitos destas foram feitos em Itália e aqui localizados, outras em Lisboa onde existe
        uma verdadeira forja de arte.
      </p>

      </div>
      <div className="script-author">Riccardo Benedetti</div>
    </div>
  )
}
