import React from "react";
import ReactDOM from "react-dom/client";

import MyHeader from "./components/Header";
import MyContent from "./components/Content";
import MyFooter from "./components/Footer";

import "./index.css";


function App() {

  return (<>
    <MyHeader />
    <MyContent />
    <MyFooter />
  </>)
}


ReactDOM.createRoot(document.getElementById('root')).render(<App />)
