export default function Artist() {
  return (
    <div className="artist-container">
      <h2>Sobre mim</h2>
      <p>Nasceu na cidade de Beja, em Portugal.</p>
      <p>Curso de Formação em Pintura na Escola de Artes Decorativas António Arroio.</p>
      <p>Curso de Artes dos Tecidos e frequência do curso de Arte e Imagem da Escola de Artes Decorativas António Arroio.</p>
      <p>Curso de Formação complementar para professores de trabalhos manuais e do décimo segundo grupo.</p>
      <p>Bacharelato em Engenharia Ambiental.</p>
      <p>Professora do segundo e terceiro Ciclo do ensino básico de 1975 a 2011.</p>
      <br/>
      <br/>

      <h2>Exposiçoes</h2>
      <h3>2013 - Exposição individual de pintura</h3>
      <h4>Sala de Exposições da Ucharia do Conde - Vila Medieval de Ourém</h4>
      <p>Tema: "A imaginação que alimenta a alma"</p>

      <h3>2013 - Exposição individual de pintura</h3>
      <h4>Livraria da Chiado Editora</h4>
      <p>Tema: "Só alcançamos a plenitude através do equilíbrio"</p>

      <h3>2003 - Exposição de pintura e escultura coletiva</h3>
      <h4>Junta da Freguesia da Portela</h4>
      <p></p>

      <h3>1998 - Exposição de desenho e pintura e outras imagens</h3>
      <h4>Museu da Electricidade de Lisboa</h4>
      <p></p>

      <h3>1997 - Exposição colectiva L.T.E.UC. /Santarem</h3>
      <h4></h4>
      <p></p>

      <h3>1996 - Exposição individual</h3>
      <h4>Casa da Cultura Jaime Lobo e Silva - Ericeira</h4>
      <p></p>

      <h3>1994 - Exposição individual</h3>
      <h4>Museu de Peniche</h4>
      <p></p>

      <h3>1991 - Primeira exposição individual no "Spiritus in Loco"</h3>
      <h4>Póvoa de Santa Iria - Vila Franca de Xira</h4>
      <p></p>

    </div>
  )
}
