import { useState, useEffect } from "react";
import { useSpring, animated } from "react-spring";
import PicCard from "./Cards.js";

export default function Gallery(props) {

  const [filter, setFilter] = useState(null);
  const [filterIsVisible, setFilterIsVisible] = useState(false);

  const [azulN, setAzulN] = useState(0);
  const [acriN, setAcriN] = useState(0);
  const [aguaN, setAguaN] = useState(0);
  const [oleoN, setOleoN] = useState(0);
  const [otheN, setOtheN] = useState(0);
  let azNum = 0;
  let acNum = 0;
  let agNum = 0;
  let olNum = 0;
  let otNum = 0;

  useEffect(() => {
    props.paintings.forEach(function(paint) {
      switch(paint.tecn) {
        case "Azulejo":
          azNum = azNum + 1;
          break;
        case "Acrilico":
          acNum = acNum + 1;
          break;
        case "Aguarela":
          agNum = agNum + 1;
          break;
        case "Óleo":
          olNum = olNum + 1;
          break;
        default:
          otNum = otNum + 1;
      }
      setAzulN(azNum);
      setAcriN(acNum);
      setAguaN(agNum);
      setOleoN(olNum);
      setOtheN(otNum);
    })

  }, []);

  

  function Highlight(isHL) {
    const hlPaintings = props.paintings.filter((item) => item.high === isHL);
    return (
        <div className="card-view">
          {hlPaintings.map((item) => <>
            <PicCard 
              key={item.id} 
              number={item.id} 
              name={item.name}
              tecn={item.tecn}
              set={props.setDetails}
            />
          </>)}
        </div> 
    )
  }

  function AllPaintings() {
    return (
      <div className="all-container">
        <h1>Destacados</h1>
        {Highlight(true)}
        <h2>Todas as obras</h2>
        {Highlight(false)}
      </div>
    )
  }

  function FilteredPaintings() {
    return (
      <div className="all-container">
        <div>
          <label>Mostrando:</label>
          <h1>{filter}</h1>
          <div className="card-view">
            {props.paintings.map((item) => <>
              {item.tecn == filter &&
                <PicCard key={item.id} 
                  number={item.id} 
                  name={item.name}
                  tecn={item.tecn}
                  set={props.setDetails}
                />
              }
              {filter == "Outros" &&
                item.tecn != "Azulejo" &&
                item.tecn != "Acrilico" &&
                item.tecn != "Aguarela" &&
                item.tecn != "Óleo" &&
                <PicCard key={item.id} 
                  number={item.id} 
                  name={item.name}
                  tecn={item.tecn}
                  set={props.setDetails}
                />
              }
            </>)}
          </div>
        </div>
      </div>
    )
  }

  const {left} = useSpring({left: filterIsVisible ? "0vw" : "100vw"});
  const {opacity} = useSpring({opacity: filterIsVisible ? 1 : 0});

  return (
    <div className="gallery-container" style={{position: "relative"}}>
      <animated.div className="filter-options" style={{
        width: "100%", opacity
      }}>
        <animated.div style={{left}}>
          <label onClick={() => {setFilter("Azulejo"); setFilterIsVisible(false)}} className="filter-button">Azulejo({azulN})</label>
          <label onClick={() => {setFilter("Acrilico"); setFilterIsVisible(false)}} className="filter-button">Acrilico({acriN})</label>
          <label onClick={() => {setFilter("Aguarela"); setFilterIsVisible(false)}} className="filter-button">Aguarela({aguaN})</label>
          <label onClick={() => {setFilter("Óleo"); setFilterIsVisible(false)}} className="filter-button">Óleo({oleoN})</label>
          <label onClick={() => {setFilter("Outros"); setFilterIsVisible(false)}} className="filter-button">Outros({otheN})</label>
          </animated.div>
      </animated.div>
      <div style={{position: "relative"}}>
        <div className="filter">
          { !filter ? 
            <div className="filter-button" onClick={() => setFilterIsVisible(!filterIsVisible)}>Filtrar por:</div> 
          : 
            <div className="filter-button" id="remove-filter" onClick={() => setFilter(null)}>
              × Remover filtro
            </div>
          }
        </div>
      </div>
      { !filter && <AllPaintings/> }
      { filter && <FilteredPaintings/>}
    </div>
  )
}
