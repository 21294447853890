export default function PaintTab({object, set}) {
  return (
    <div className="details-container">
      <div className="image-container">
        <img src={"./paintings/" + object.id + "/paint.jpg"} />
      </div>
        <div className="details-text">
          <h1>{object.name}</h1>
          <p id="tecyr">{object.tecn} - {object.year} <br/>{object.size}</p>
          <p id="desc">{object.desc}</p>
          <p id="avail">
              <span>ID: {object.id}</span><br/>
          <div style={{position: "relative"}}>
            {object.aval ? 
              <span id="av">Disponível em: <span>{object.aval}</span></span>
            : <span id="notav">Não disponível</span> 
            }
          </div>
          </p>
      </div>
    </div>
  )
}
