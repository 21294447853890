import { useEffect, useRef, useState } from 'react';
import { useSpring, animated } from 'react-spring';

export default function PicCard(props) {

  const hiddenRef = useRef();
  const [isVisible, setIsVisible] = useState(true);

  const handleScroll = () => {
    setIsVisible(false);
    if(window.pageYOffset + window.innerHeight >= hiddenRef.current.offsetTop) {
      setIsVisible(true);
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    }
  }, []);

  const {opacity} = useSpring({opacity: isVisible ? 1 : 0});

  return (
    <animated.div 
      className="card-container" 
      style={{opacity}}
      onClick={() => props.set(props.number)}
      ref = {hiddenRef}
    >
      <img src={"paintings/" + props.number + "/300p.jpg"} />
      <div className="card-details">
        <div>
          <h3>{props.name}</h3>
          <p>{props.tecn}</p>
        </div>
        <p>{props.number}</p>
      </div>
    </animated.div>
  )
}
