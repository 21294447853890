import { useState } from "react";
import { useSpring, animated } from 'react-spring';

import Home from "./Home.js";
import Gallery from "./Gallery.js";
import Artist from "./Artist.js";
import PaintTab from "./PaintTab.js";

import paintings from "../data/paintings.json";

export default function MyContent(props) {
  
  const [page, setPage] = useState("Home")
  const [paint, setPaint] = useState()
  const [details, setDetails] = useState(null)

  function NavItem({path}) {
    return (
      <li className="nav-item" onClick={() => {
        setPage(path);
        window.scrollTo({top: 0}); 
      }}>
          {path}
      </li>
    )
  }
  
  
  function PaintView({id}) {
    return (
      <div className="paint-view" onClick={(event) => {
        event.target === event.currentTarget && setDetails(null)
      }}>
        <PaintTab 
          object={paintings[paintings.findIndex(item => item.id === id)]}
          set={setDetails}
        />
      </div>
    )
  }

  function Contact() {
    return (
      <div className="contact-container">
        <div style={{height: "60%", display: "flex", flexDirection: "column", alignItems: "center"}}>
            <h2>Correio</h2>
            <a href="mailto:luisa@luisapascoa.art"><h3>luisa@luisapascoa.art</h3></a>
            <hr/>
            <img src="./assets/Cartolina.jpg" style={{maxWidth: "90vw"}}/>
        </div>
      </div>
    )
  }

  let homeVisible = false;
  let galleryVisible = false;
  let artistVisible = false;
  let contactVisible = false;

  let homeStyle = {opacity: 0};
  let galleryStyle = {opacity: 0};
  let artistStyle = {opacity: 0};
  let contactStyle = {opacity: 0};

  switch(page) {
    case "Home":
      homeVisible = true;
      break;
    case "Galeria":
      galleryVisible = true;
      break;
    case "Artista":
      artistVisible = true;
      break;
    case "Contato":
      contactVisible = true;
      break;
  }

  homeStyle.opacity = useSpring({opacity: homeVisible ? 1 : 0});
  galleryStyle.opacity = useSpring({opacity: galleryVisible ? 1 : 0});
  artistStyle.opacity = useSpring({opacity: artistVisible ? 1 : 0});
  contactStyle.opacity = useSpring({opacity: contactVisible ? 1 : 0});

  return (<>
    <nav className="nav-bar">
      <ul>
        <NavItem path="Home" />
        <NavItem path="Galeria" />
        <NavItem path="Artista" />
        <NavItem path="Contato" />
      </ul>
    </nav>
    
    { details && <PaintView id={details} /> }

    <main style={{backgroundImage: "url(./assets/Background.jpg)", backgroundSize: "50%"}}>
      <animated.div style={{...homeStyle.opacity, display: homeVisible ? "" : "none"}}><Home /></animated.div>
      <animated.div style={{...galleryStyle.opacity, display: galleryVisible ? "" : "none"}}><Gallery setDetails={setDetails} paintings={paintings}/></animated.div>
      <animated.div style={{...artistStyle.opacity, display: artistVisible ? "" : "none"}}><Artist /></animated.div>
      <animated.div style={{...contactStyle.opacity, display: contactVisible ? "" : "none"}}><Contact /></animated.div>
    </main>

  </>)
}
