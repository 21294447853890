import { useState, useEffect } from "react";

export default function MyHeader(props) {
  const ig = "https://instagram.com/luisa_pascoa/"
  const fb = "https://facebook.com/isapascoa/"
  //const color = "rgba(10,80,100,"
  const color = "rgba(8,56,69,"

  // States for size and color changing
  const [panelHeight, setPanelHeight] = useState(window.innerHeight);
  const [panelWidth, setPanelWidth] = useState(window.innerWidth);
  const [percentage, setPercentage] = useState(100);

  // Determine if scroll is enabled or not
  const [canScroll, setCanScroll] = useState(false);
  document.body.style.overflowY = "visible";
  document.body.style.overflowX = "hidden";
  if (!canScroll) {
    document.body.style.overflowY = "hidden";
  };

  // On window resizing, change height
  function handleResize() {
    setPanelHeight(window.innerHeight);
    setPanelWidth(window.innerWidth);
  }
  
  // Resize the header on button press
  const resizeHeader = () => {
    let prevHeight = panelHeight;
    let height = window.innerHeight;
    let percent = 100;

    setCanScroll(true);
    window.removeEventListener("resize", handleResize);

    let timer = setInterval(function() {
      if (prevHeight <= 100) {
        clearInterval(timer);
      };
      prevHeight = (prevHeight - 15) < 100 ? 100 : (prevHeight - 15);
      percent = (100/(height-100)*(prevHeight-100));

      setPanelHeight(prevHeight);
      setPercentage(percent);
    }, 10);
  }

  // Change backgrounds
  const [backgroundImage, setBackgroundImage] = useState("assets/background-paintings/Background1.jpg")
  const backgroundChanger = () => {
    let index = 0;
    let images = ["Background1","Background2","Background3", "Background4"];
    setInterval(changeImage, 10000);
    function changeImage() {
      index += 1;
      if (index >= images.length) {
        index = 0;
      }
      let anim = document.getElementById("animated-container");
      setBackgroundImage("assets/background-paintings/" + images[index] + ".jpg");
      anim.classList.remove("background-container");
      void anim.offsetWidth; // Reflow
      anim.classList.add("background-container");
    }
  }

  useEffect(() => {

    backgroundChanger();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    }
  }, []);


  return (
    <div className="header">
      <div className="head-top" 
        style={{height: (panelHeight) + "px", backgroundColor: color + (1 - (percentage/100)) + ")"}}
      >


        <div id="animated-container" className="background-container" style={{
          position: "absolute", top: "0", left: "0", width: "100%", height: "100%"
        }}>
          <img src={backgroundImage} style={{
            zIndex: "0", opacity: (percentage * 0.9 + 10) + "%", objectFit: "cover",
            height: "120%", width: "120%", 
          }}/>
        </div>


        <div className="title" style={{
            position: "absolute", left: (percentage/100 * 10) + "%", top: (percentage/100 * 20) + "%"
        }}>
          <img src="assets/Border.png" style={{
            width: (panelWidth / 100 * 80) + "px", height: (panelWidth / 100 * 80 / 2) + "px", maxWidth: "500px", maxHeight: "250px",
            opacity: (percentage) + "%"
          }}/>
          <div style={{
            position: "absolute", top: (percentage/100 * 20 + 30) + "px", left: (percentage/100 * 20 + 30) + "px"
          }}>
            <h1 style={{zIndex: 1}}>Luisa Páscoa</h1>
            <h2 style={{zIndex: 1}}>Galeria de Arte Digital</h2>
          </div>
          <div className="startButton" onClick={() => resizeHeader()} style={{
            position: "relative", top: "100px", padding: "20px 35px 15px 35px", width: "200px", left: "100px", display: "flex", justifyContent: "center",
          }}>
            <span style={{fontSize: "40px"}}>COMEÇAR</span>
          </div> 
        </div>
    

        <div className="social" style={{
          opacity: (100-percentage) + "%",
          position: "absolute", right: (percentage/100 * 10) + "%", top: (percentage/100 * 30) + "%",
          padding: "35px"
        }}>
          <a href={ig} target="_blank">@luisa_pascoa<img src="assets/Instagram.png" /></a>
          <a href={fb} target="_blank">Maria Luisa Páscoa<img src="assets/Facebook.png" /></a>
        </div>


      </div>
    </div>
  )
}
